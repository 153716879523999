import React from 'react';
import {  Container } from '@mui/material';
import PackingGrid from '../components/packing/PackingGrid';



const PackingPage = () => {
  return (
    <Container>
        <PackingGrid/>
    </Container>
  );
};
export default PackingPage;
